import React from "react";
import styles from "./widget.module.scss";
import Button from "@material-ui/core/Button";
import { Location } from "@reach/router";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import magnifyingGlass from "../../../static/images/temp/search_icon.svg";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const themeDropdown = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        fontFamily: "Lato",
      },
    },
    MuiFormControl: {
      fullWidth: {
        height: "100%",
      },
    },
    MuiInputBase: {
      root: {
        height: "100%",
      },
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        display: "none",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          border: "2px solid",
          borderColor: "#ddd",
        },
        "&$focused $notchedOutline": {
          border: "2px solid",
          borderColor: "#ddd",
        },
      },
    },
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        display: "none",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          border: "2px solid",
          borderColor: "#ddd",
        },
        "&$focused $notchedOutline": {
          border: "2px solid",
          borderColor: "#ddd",
        },
      },
    },
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      best_prices: [],
      weight: 0,
      redirectPro: false,
      disableSubmit: true,
      total: 0,
      slug: "",
      productId: 0,
      url: null,
      longName: false,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.calculatePrice = this.calculatePrice.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  setSubmitUrl = () => {
    var url =
      "/co/cart/g/" +
      this.props.buyerId +
      "/" +
      this.state.productId +
      "?quantity=" +
      this.state.weight;
    this.setState({ url: url });
  };

  calculatePrice = function() {
    var priceToCalculate = 0;
    var redirectPro = false;
    var value = this.state.weight;

    if (
      !isNaN(value) &&
      this.state.best_prices.length &&
      parseInt(this.state.best_prices[0].start) < value
    ) {
      priceToCalculate = this.state.best_prices[0].price;
      for (var i = 0; this.state.best_prices.length > i; i++) {
        if (parseInt(this.state.best_prices[i].start) > value) {
          break;
        } else {
          priceToCalculate = this.state.best_prices[i].price;
        }
        if (i > 2) {
          redirectPro = true;
        } else {
          redirectPro = false;
        }
      }
      this.setState({ disableSubmit: false });
    } else {
      value = 0;
      this.setState({ disableSubmit: true });
      redirectPro = false;
    }

    let total = value * priceToCalculate;
    if (total >= 3500) {
      redirectPro = true;
    }

    this.setState({ total: total });
    this.setState({ redirectPro: redirectPro }, () => this.setSubmitUrl(value));
  };

  handleInputChange = function(event) {
    this.setState({ weight: event.target.value }, () => this.calculatePrice());
  };

  handleSelect = function(events) {
    var element = this.props.products.find(
      o => o.productName === events.target.value
    );

    this.setState({ longName: events.target.value.length > 24 });

    if (element) {
      // Force a blur if element is selected
      const previousElement = this.state.productId;
      if (previousElement === 0 || previousElement !== element.productID) {
        events.target.blur();
      }

      this.setState(
        {
          slug: element.slug,
          productId: element.productID,
          best_prices: element.best_prices,
        },
        () => this.calculatePrice()
      );
    } else {
      this.setState(
        {
          slug: "",
          productId: 0,
          best_prices: 0,
        },
        () => this.calculatePrice()
      );
    }
  };

  onSubmit = event => {
    event.preventDefault();

    window.location.replace(this.state.url);
  };

  render() {
    // Workaround to make Autocomplete work on Firefox
    const onChange = this.handleSelect;

    return (
      <Location>
        {locationProps => (
          <div className={styles.widget}>
            <div className={[styles.widgetWrapper].join(" ")}>
              <form className={styles.form} onSubmit={this.onSubmit}>
                <div className={styles.inputsWrapper}>
                  <div
                    className={
                      this.state.longName
                        ? [
                            styles.materialInputWrapper,
                            styles.inputsWrapperBigSelection,
                          ].join(" ")
                        : styles.materialInputWrapper
                    }
                  >
                    <span className={[styles.inputTitle].join(" ")}>
                      Ihr Material
                    </span>

                    <MuiThemeProvider theme={themeDropdown}>
                      <Autocomplete
                        id="combo-box-demo"
                        autoHighlight={true}
                        autoSelect={true}
                        autoComplete={false}
                        clearOnEscape={true}
                        onSelect={this.handleSelect}
                        options={this.props.products.map(val => {
                          var value = {};
                          value.productName = val.productName;
                          value.slug = val.slug;
                          return value;
                        })}
                        getOptionLabel={option => option.productName}
                        className={[styles.autoComplete, "inputAmount"].join(
                          " "
                        )}
                        renderInput={params => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "off";
                          inputProps.disabled =
                            this.props.products.length === 0;

                          return (
                            <TextField
                              {...params}
                              placeholder="Ihr Material"
                              inputProps={inputProps}
                              onBlur={onChange}
                              variant="outlined"
                              fullWidth
                            />
                          );
                        }}
                      />
                      {this.state.productId === 0 ? (
                        <img
                          src={magnifyingGlass}
                          className={styles.magnifyingGlass}
                          alt="glass"
                        />
                      ) : null}
                    </MuiThemeProvider>
                  </div>

                  <div
                    className={[
                      styles.quantityWrapper,
                      "widgetQuantityWrapper",
                    ].join(" ")}
                  >
                    <span className={[styles.inputTitle].join(" ")}>
                      {" "}
                      Menge
                    </span>
                    <MuiThemeProvider theme={theme}>
                      <TextField
                        className={[
                          "primaryColor",
                          "inputAmount",
                          "inputAmountWidget",
                          styles.autoComplete,
                          styles.inputAmount,
                        ].join(" ")}
                        onChange={this.handleInputChange}
                        id="outlined-bare"
                        autoComplete="off"
                        type="number"
                        margin="normal"
                        variant="outlined"
                        placeholder="Menge"
                      />
                    </MuiThemeProvider>
                    <span
                      className={[styles.inputUnit, "inputUnitSpan"].join(" ")}
                    >
                      {" "}
                      kg{" "}
                    </span>
                  </div>
                </div>
                <div
                  className={[styles.totalWrapper, "widgetWrappercolor"].join(
                    " "
                  )}
                >
                  <div
                    className={
                      this.state.disableSubmit
                        ? [styles.total].join(" ")
                        : styles.total
                    }
                  >
                    {this.props.currencySymbol}{" "}
                    {parseFloat(
                      Math.round(this.state.total * 100) / 100
                    ).toFixed(2)}{" "}
                  </div>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={[styles.button, "productBuyButton"].join(" ")}
                    disabled={this.state.disableSubmit}
                  >
                    {this.state.redirectPro
                      ? "GROSSKUNDE"
                      : "IN DEN VERKAUFSKORB"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </Location>
    );
  }
}
