import React from "react";
import CardCategory from "../../components/category/categoryCard";
import styles from "./categoryPage.module.scss";
import Layout from "../../components/layout/layout";
import Widget from "../../components/widget/widget";
import WebshopContext from "../../providers/WebshopProvider";

import CircularProgress from "@material-ui/core/CircularProgress";

export default class categoryPage extends React.Component {
  getTitle = () => {
    const title = this.props.pageContext.seoTitle || "%%CATEGORY%%";
    return title.replace("%%CATEGORY%%", "Schrott Ankauf in der Nähe");
  };

  getMetaDescription = () => {
    const description = this.props.pageContext.seoDescription || "%%CATEGORY%%";
    return description.replace("%%CATEGORY%%", "Schrott");
  };

  render() {
    return (
      <Layout
        webshopfooterName={this.props.pageContext.webshopfooterName}
        webshopAddress={this.props.pageContext.webshopAddress}
        webshopZipCode={this.props.pageContext.webshopZipCode}
        webshopOpeningHours={this.props.pageContext.webshopOpeningHours}
        webshopCity={this.props.pageContext.webshopCity}
        webshopPrivacyLink={this.props.pageContext.webshopPrivacyLink}
        webshopCompanyInfoLink={this.props.pageContext.webshopCompanyInfoLink}
        favicon={this.props.pageContext.favicon}
        footerLink={this.props.pageContext.webshopTermsLink}
        webshopName={this.props.pageContext.webshopName}
        logoUrl={this.props.pageContext.webshopLogoUrl}
        buyerGroupId={this.props.pageContext.buyerGroupId}
        webshopLogo={this.props.pageContext.webshopLogo}
        title={this.getTitle()}
        metaDescription={this.getMetaDescription()}
        headers={this.props.pageContext.headers}
        footers={this.props.pageContext.footers}
      >
        <WebshopContext.Consumer>
          {webshop => (
            <div>
              <div
                className={[styles.topImageWrapper, "banner"].join(" ")}
              ></div>
              <div
                className={[styles.topImageWrapperMobile, "bannerMobile"].join(
                  " "
                )}
              ></div>
              <Widget
                products={webshop.allProducts ? webshop.allProducts : []}
                buyerId={this.props.pageContext.buyerGroupId}
                currencySymbol={this.props.pageContext.currencySymbol}
              />
              <div className={styles.cardCategoryWrapper}>
                <div className={styles.cardCategoryGroup}>
                  {webshop.categories && webshop.categories.length ? (
                    webshop.categories.map((value, index) => {
                      return (
                        <CardCategory
                          key={value.id}
                          id={value.id}
                          name={value.name}
                          best_price={value.best_price}
                          image_id={value.images[0]._id}
                          slug={value.slug}
                          currencySymbol={this.props.pageContext.currencySymbol}
                        />
                      );
                    })
                  ) : (
                    <div className={styles.loading}>
                      <CircularProgress />
                    </div>
                  )}
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.pageContext.webshopFreeText,
                }}
              ></div>
              <div
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{
                  __html: this.props.pageContext.webshopMap,
                }}
              ></div>
            </div>
          )}
        </WebshopContext.Consumer>
      </Layout>
    );
  }
}
