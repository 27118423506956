import React from "react";
import styles from "./categoryCard.module.scss";
import { Link } from "gatsby";
import { Location } from "@reach/router";
import nextImg from "../../../static/images/temp/altmetallCardButton.svg";

function getImageRightPath(url, width) {
  return url.split("357").join(width);
}

function generateImageSizes(url) {
  var rst = [];
  for (var i = 100; i <= 960; i = i + 50) {
    rst.push(getImageRightPath(url, i) + "  " + i + "w");
  }
  rst.push(getImageRightPath(url, 357) + "  " + 961 + "w");
  return rst.join();
}

const ResponsiveImage = props => (
  <div>
    <img
      alt="material"
      className={styles.materialImage}
      src={props.imageUrl}
      srcSet={`${generateImageSizes(props.imageUrl)}`}
    />
  </div>
);

const getCategoryName = category => {
  if (category.indexOf("/") !== -1) {
    return category.replace("/", "  / ");
  }
  return category;
};

const getCategoryClass = category => {
  return category === "Elektronikschrott" ? styles.small : "";
};

export default props => (
  <Location>
    {locationProps => (
      <Link
        className={styles.linkContainer}
        to={
          locationProps.location.pathname.replace("/s", "") + "/" + props.slug
        }
      >
        <div className={styles.cardWrapper}>
          <div className={styles.nameGroup}>
            <h2
              className={styles.cardTitle + " " + getCategoryClass(props.name)}
            >
              {getCategoryName(props.name)}
            </h2>
            <img src={nextImg} alt="Arrow next step" />
          </div>
          <div className={styles.priceContainer}>
            <p className={[styles.categoryPriceText].join(" ")}> bis zu </p>
            <span>
              {props.currencySymbol}{" "}
              {parseFloat(Math.round(props.best_price * 100) / 100).toFixed(2)}
            </span>{" "}
            <p className={[styles.categoryPriceText].join(" ")}> pro Kg </p>
          </div>
          <ResponsiveImage
            imageUrl={
              "https://images.scrap24.com/" +
              props.slug
                .split("-")
                .join("")
                .trim()
                .toLowerCase() +
              "-357x125-" +
              props.image_id +
              ".jpg"
            }
          ></ResponsiveImage>
        </div>
      </Link>
    )}
  </Location>
);
